import React from 'react';

import config from '../../config';

import PageFooterDate from './PageFooterDate';

export default function PageFooter() {
  return (
    <footer id="footer">
      <ul className="copyright">
        <li>
          &copy;
          <PageFooterDate /> {config.authorName}
        </li>
      </ul>
    </footer>
  );
}
