module.exports = {
  siteTitle: 'Karolina Czarnota (Osuch) - Optometrysta', // <title>
  manifestName: 'Optometrysta',
  manifestShortName: 'Wizytówka', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'Karolina Czarnota (Osuch)',
  heading: 'Optometrysta',
  socialLinks: [
    {
      icon: 'fa-eye',
      name: 'Znany Lekarz',
      url: 'https://www.znanylekarz.pl/karolina-osuch/optometrysta/warszawa/',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/karolina-czarnota-8182991b1/',
    },
    // {
    //   icon: 'fa-instagram',
    //   name: 'Instagram',
    //   url: 'https://www.instagram.com/carolinexosuch/',
    // },
  ],
};
