import React from 'react';

import config from '../../config';

const pic = require('../assets/images/karolinaczarnota.jpg');

export default function Footer() {
  return (
    <header>
      <span className="avatar">
        <img height="128px" src={pic} alt="Karolina Czarnota (Osuch) - zdjęcie profilowe" />
      </span>
      <h1>{config.authorName}</h1>
      <p>{config.heading}</p>
    </header>
  );
}
