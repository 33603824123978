const STARTED_DATE = `2021`;

const PageFooterDate = () => {
  const now = new Date();
  const nowFullYear = now.getFullYear();

  if (nowFullYear > 2021) {
    return `${STARTED_DATE} - ${nowFullYear}`;
  }

  return STARTED_DATE;
};

PageFooterDate.propTypes = {};

export default PageFooterDate;
